export const getGradient = (ctx, chartArea) => {
  let width, height, gradient;
  const chartWidth = chartArea.right - chartArea.left;
  const chartHeight = chartArea.bottom - chartArea.top;
  if (!gradient || width !== chartWidth || height !== chartHeight) {
    // Create the gradient because this is either the first render
    // or the size of the chart has changed

    gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
    gradient.addColorStop(0, 'transparent');
    gradient.addColorStop(1, getCSS('--p'));
  }

  return gradient;
}

export const getCSS = (varname, alpha = 1) => {
  const value = getComputedStyle(document.documentElement).getPropertyValue(varname);
  if(value.includes("em") || value.includes("px")) {
    return value
  }
  return `oklch(${getComputedStyle(document.documentElement).getPropertyValue(varname)})`
}

const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div')

  if (!tooltipEl) {
    tooltipEl = document.createElement('div')
    tooltipEl.id = 'chartjs-tooltip'
    tooltipEl.style.background = getCSS('--n')
    tooltipEl.style.borderRadius = getCSS('--rounded-btn')
    tooltipEl.style.opacity = 1
    tooltipEl.style.pointerEvents = 'none'
    tooltipEl.style.position = 'absolute'
    tooltipEl.style.transform = 'translate(-50%, 50%)'
    tooltipEl.style.transition = 'all .1s ease'
    tooltipEl.style.minWidth = '250px'

    chart.canvas.parentNode.appendChild(tooltipEl)
  }

  return tooltipEl
}

export const externalTooltipHandler = (context) => {
  const { chart, tooltip } = context
  const offset = chart.canvas.getBoundingClientRect()
  const tooltipEl = getOrCreateTooltip(chart)

  if (tooltipEl && offset && window.innerWidth < 768) {
    tooltipEl.style.top = offset.y + offset.height + window.scrollY + 15 + 'px'
    tooltipEl.style.left = offset.x + 'px'
    tooltipEl.style.right = null
    tooltipEl.style.opacity = 1
  }

  if (tooltip.opacity === 0) {
    tooltipEl.style.display = 'none'
    return
  }

  if (tooltip.body) {
    const data = chart.config.data.datasets.find((set) => set.yAxisID == "y")
    const dataIndex = chart.config.data.datasets.indexOf(data)
    const tooltipData = tooltip.dataPoints.find((dataPoint) => dataPoint.datasetIndex == dataIndex)

    tooltipEl.innerHTML = `
      <aside class="flex flex-col text-neutral-content">
        <div class="flex justify-between items-center">
          <span class="text-sm font-bold uppercase whitespace-nowrap flex mr-4">${data.label}</span>
        </div>
        ${tooltipData.label ? `
        <div class="flex flex-col">
          <div class="flex flex-row justify-between items-center">
            <span class="flex items-center grow mr-4">
              <span>${tooltipData.label}</span>
            </span>
            <span class="font-bold">${tooltipData.formattedValue}</span>
          </div>
        </div>` : ''}
      </aside>
    `
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas

  tooltipEl.style.opacity = 1
  tooltipEl.style.display = null
  tooltipEl.style.left = positionX + tooltip._eventPosition.x + 'px'
  tooltipEl.style.top = positionY + tooltip._eventPosition.y + 'px'
  tooltipEl.style.font = tooltip.options.bodyFont.string
  tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px'
  tooltipEl.style.zIndex = 9999
}
