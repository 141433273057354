import {Controller} from "@hotwired/stimulus"
import {Chart} from 'chart.js';
import {externalTooltipHandler, getCSS, getGradient} from "./utils"

import ChartDataLabels from 'chartjs-plugin-datalabels';

export default class ChartJS extends Controller {
  static targets = ["canvas"]
  static values = {
    type: { type: String, default: "bar" },
    data: Object,
    options: Object,
  }

  connect() {
    const element = this.hasCanvasTarget ? this.canvasTarget : this.element
    const data = this.buildChartData()
    const options = this.buildChartOptions()

    this.chart = new Chart(element.getContext("2d"), {
      plugins: [ChartDataLabels],
      type: this.typeValue,
      data: data,
      options: options,
    })
  }

  disconnect() {
    this.chart.destroy()
    this.chart = undefined
  }

  buildChartData() {
    return {
      labels: Object.keys(this.chartData),
      datasets: [
        {
          label: 'Unique visitors',
          data: Object.values(this.chartData),
          borderSkipped: true,
          color: getCSS('--p'),
          yAxisID: 'y',
          backgroundColor: (ctx) => {
            const canvas = ctx.chart.ctx;
            const chartArea = ctx.chart.chartArea;
            if (!chartArea) {
              return
            }
            const gradient = canvas.createLinearGradient(chartArea.left, 0, chartArea.right, 0);

            gradient.addColorStop(0, 'transparent');
            gradient.addColorStop(1, getCSS('--p'));

            return gradient;
          },
          datalabels: {
            labels: {
              title: {
                formatter: function(value, context) {
                  return context.chart.data.labels[context.dataIndex]
                },
                align: 'end',
                anchor: 'start',
              },
              value: {
                align: 'start',
                anchor: 'end',
              }
            }
          }
        }
      ],
    }
  }

  buildChartOptions() {
    return {
      ...this.defaultOptions,
      ...this.optionsValue,
    }
  }

  createBackgroundColor() {
    return function(context) {
      const chart = context.chart
      const { ctx, chartArea } = chart

      if (!chartArea) {
        return
      }
      return getGradient(ctx, chartArea)
    }
  }

  get chartData() {
    if (!this.hasDataValue) {
      console.warn("[@stimulus-components/chartjs] You need to pass data as JSON to see the chart.")
    }
    return this.dataValue
  }

  get defaultOptions() {
    return {
      indexAxis: 'y',
      maintainAspectRatio: false,
      animation: true,
      plugins: {
        legend: { display: false },
        tooltip: {
          enabled: false,
          position: 'nearest',
          external: externalTooltipHandler
        },
        datalabels: {
          color: 'white',
          font: {
            size: 16,
          },
          display: true,
        }
      },
      scales: {
        y: {
          display: false,
        },
        x: {
          display: false,
          ticks: {
            stepSize: 1,
          }
        }
      }
    }
  }
}
