import { Controller } from "@hotwired/stimulus"
import { Chart } from "chart.js/auto"
import { getCSS, getGradient, externalTooltipHandler } from "./utils"

export default class ChartJS extends Controller {
  static targets = ["canvas"]
  static values = {
    type: { type: String, default: "line" },
    data: Object,
    options: Object,
  }

  connect() {
    const element = this.hasCanvasTarget ? this.canvasTarget : this.element
    const data = this.buildChartData()
    const options = this.buildChartOptions()

    this.chart = new Chart(element.getContext("2d"), {
      plugins: [this.createHorizontalLinePlugin()],
      type: this.typeValue,
      data: data,
      options: options,
    })
  }

  disconnect() {
    this.chart.destroy()
    this.chart = undefined
  }

  buildChartData() {
    return {
      labels: Object.keys(this.chartData),
      datasets: [
        {
          label: 'Unique visitors',
          data: Object.values(this.chartData),
          borderColor: getCSS('--p'),
          backgroundColor: this.createBackgroundColor(),
          fill: 'start',
          borderWidth: 1.5,
          pointStyle: 'circle',
          pointBorderColor: 'rgba(0, 0, 0, 0)',
          pointBackgroundColor: 'rgba(0, 0, 0, 0)',
          pointHoverBackgroundColor: getCSS('--a'),
          pointHoverBorderColor: getCSS('--a'),
          color: getCSS('--p'),
          yAxisID: 'y'
        }
      ],
    }
  }

  buildChartOptions() {
    return {
      ...this.defaultOptions,
      ...this.optionsValue,
    }
  }

  createBackgroundColor() {
    return function(context) {
      const chart = context.chart
      const { ctx, chartArea } = chart

      if (!chartArea) {
        return
      }
      return getGradient(ctx, chartArea)
    }
  }

  createHorizontalLinePlugin() {
    return {
      afterDraw: chart => {
        if (chart.tooltip?._active?.length) {
          let x = chart.tooltip._active[0].element.x
          let yAxis = chart.scales.y
          let ctx = chart.ctx
          ctx.save()
          ctx.beginPath()
          ctx.moveTo(x, yAxis.top)
          ctx.lineTo(x, yAxis.bottom)
          ctx.lineWidth = 1.5
          ctx.strokeStyle = getCSS('--a')
          ctx.stroke()
          ctx.restore()
        }
      }
    }
  }

  get chartData() {
    if (!this.hasDataValue) {
      console.warn("[@stimulus-components/chartjs] You need to pass data as JSON to see the chart.")
    }
    return this.dataValue
  }

  get defaultOptions() {
    return {
      maintainAspectRatio: false,
      animation: true,
      plugins: {
        datalabels: {
          display: false
        },
        legend: { display: false },
        tooltip: {
          enabled: false,
          position: 'nearest',
          external: externalTooltipHandler
        }
      },
      elements: { line: { tension: 0.4 } },
      interaction: {
        mode: 'nearest',
        axis: 'x',
        intersect: false
      },
      responsive: true,
      scales: {
        y: {
          display: false,
          ticks: { maxTicksLimit: 4 },
          grid: {}
        },
        x: {
          display: false,
          grid: { drawOnChartArea: false },
          time: {},
          ticks: {}
        }
      }
    }
  }
}
